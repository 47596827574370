import React, { useState, useEffect } from 'react';
import { Label } from 'models/label';
import { ReactNode } from 'react';
import SingleLabel from './singleLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import axios from 'axios';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

interface Props {
  labelList: Label[];
  selectedLabels: string[];
  onSelected: any;
  onUnselected: any;
  children?: ReactNode;
}

const LabelListView = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<Label | null>(null);
  const [productDetails, setProductDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const labelChanged = (l: { id: string; checked: boolean }) => {
    if (l.checked) {
      props.onSelected(l.id);
    } else {
      props.onUnselected(l.id);
    }
  };

  const handleOpen = (label: Label) => {
    setSelectedLabel(label);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedLabel(null);
    setProductDetails(null);
    setError(null);
  };

  const getProductById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
  };

  useEffect(() => {
    if (selectedLabel) {
      setIsLoading(true);
      getProductById(selectedLabel.entity_id)
        .then((response: any) => {
          setProductDetails(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching product details:', error);
          setError(error);
          setIsLoading(false);
        });
    }
  }, [selectedLabel]);

  return (
    <div className="list-view ml-2">
      {props.children}
      <div className="card my-3">
        <div className="label-list-scrollable">
          <div className="label-list-single-label">
            {props.labelList?.map((label) => (
              <SingleLabel
                isSelected={props.selectedLabels.includes(label.id)}
                key={label.id}
                label={label}
                onLabelChange={labelChanged}
                onLabelClick={handleOpen}
              />
            ))}
          </div>
        </div>
      </div>

      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth
        maxWidth="md" // Changed to 'md' for a wider dialog
      >
        {isLoading ? (
          <DialogContent>
            <Typography variant="body1">Loading...</Typography>
          </DialogContent>
        ) : error ? (
          <DialogContent>
            <Typography variant="body1" color="error">
              Error loading product details.
            </Typography>
          </DialogContent>
        ) : productDetails ? (
          <>
            <DialogTitle id="dialog-title">{productDetails.name}</DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                {/* Product Image */}
                <Grid item xs={12} sm={4}>
                  {productDetails.photos?.length ? (
                    <img
                      src={productDetails.photos[0].url}
                      alt={productDetails.name}
                      className="single-label-modal-image"
                    />
                  ) : (
                    <div className="single-label-modal-image-placeholder">
                      <NoPhotographyIcon style={{ fontSize: 50, color: '#9e9e9e' }} />
                    </div>
                  )}
                </Grid>

                {/* Product Details */}
                <Grid item xs={12} sm={8}>
                  <Typography variant="h6" gutterBottom>
                    {productDetails.full_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    {productDetails.category?.name}
                  </Typography>
                  <Divider style={{ margin: '16px 0' }} />
                  {/* Additional Details */}
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>EAN:</strong> {productDetails.ean || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>UPC:</strong> {productDetails.upc || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Size:</strong> {productDetails.size || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Pack Size:</strong> {productDetails.pack_size || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Pack Type:</strong> {productDetails.pack_type || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Region:</strong> {productDetails.region || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Brand:</strong> {productDetails.brand?.name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Category:</strong> {productDetails.category?.name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Supplier:</strong> {productDetails.supplier?.name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Brand Family:</strong> {productDetails.brand_family?.name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Classification:</strong> {productDetails.classification?.name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Subclassification:</strong> {productDetails.subclassification?.name || 'N/A'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
    </div>
  );
};

export default LabelListView;
